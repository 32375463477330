<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card-text>
      <v-text-field
        label="Name *"
        v-model="name"
        :rules="nameRules"
        required
      ></v-text-field>
      <!-- <v-text-field
        label="Offer name *"
        v-model="slug"
        :rules="nameRules"
        required
        @input="stringToSlug(slug)"
      ></v-text-field> -->

      <v-autocomplete
        v-model="target_offer"
        :items="targetOffersList"
        :rules="fieldRules"
        item-text="name"
        label="Target offer *"
        required
        return-object
        :search-input.sync="searchTargetOffers"
        @focus="() => onLoadTargetOffers()"
      ></v-autocomplete>

      <v-autocomplete
        v-model="country"
        :items="countries_list"
        :loading="isLoadingCountries"
        :search-input.sync="searchCountries"
        @focus="() => onLoadCountries()"
        :rules="fieldRules"
        placeholder="Search"
        item-text="name"
        item-value="code"
        label="Country *"
        required
      ></v-autocomplete>

      <v-autocomplete
        v-model="network"
        :items="networks"
        :loading="isLoadingNetworks"
        :rules="fieldRules"
        item-text="name"
        item-value="id"
        label="Network *"
        required
      ></v-autocomplete>
      <v-autocomplete
        v-model="domain"
        :items="domainsList"
        :loading="isLoadingDomains"
        :search-input.sync="searchDomain"
        @focus="() => loadDomainsList()"
        :rules="fieldRules"
        placeholder="Search"
        item-text="domain"
        item-value="id"
        label="Domain *"
        required
        return-object
      ></v-autocomplete>

      <v-text-field
        label="End url*"
        v-model="end_url"
        :rules="endUrlRules"
        persistent-hint
        required
      ></v-text-field>
      <v-select
        :items="isActiveFilter"
        label="Redirect type"
        v-model="redirect_type"
        item-text="label"
        item-value="value"
      ></v-select>
    </v-card-text>

    <v-divider class="mx-4"></v-divider>

    <v-card-title>Filter</v-card-title>

    <v-card-text>
      <v-row class="mb-4">
        <v-col cols="12" sm="6" md="3">
          <v-switch
            v-model="enabled"
            label="Enabled"
            color="primary"
            hide-details
          ></v-switch>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-switch
            v-model="block_proxies"
            label="Block proxy"
            color="primary"
            hide-details
            :disabled="!enabled"
          ></v-switch>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-switch
            v-model="block_empty_referers"
            label="Block empty referer"
            color="primary"
            hide-details
            :disabled="!enabled"
          ></v-switch>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-switch
            v-model="block_countries"
            label="Block countries"
            color="primary"
            hide-details
            :disabled="!enabled"
          ></v-switch>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider class="mx-4"></v-divider>
    <v-card-title>Rules</v-card-title>
    <v-card-title class="mt-0 pt-0">
      <v-switch
        color="primary"
        hide-details
        v-model="rules_status"
        :label="`Unique rules  ${rules_status ? 'included' : 'excluded'}`"
      ></v-switch>
    </v-card-title>
    <template v-if="rules_status">
      <v-tabs :vertical="windowWidth > 991" :show-arrows="windowWidth < 991">
        <v-tab>
          <div class="pa-3">
            <v-badge
              :color="has_leads.is_active ? 'blue' : 'blue-grey lighten-3'"
              :content="`${has_leads.urls.length}`"
              :offset-x="-3"
            >
              Has leads
            </v-badge>
          </div>
        </v-tab>
        <v-tab>
          <div class="pa-3">
            <v-badge
              :color="no_leads.is_active ? 'blue' : 'blue-grey lighten-3'"
              :content="`${no_leads.urls.length}`"
              :offset-x="-3"
            >
              No leads
            </v-badge>
          </div>
        </v-tab>
        <v-tab>
          <div class="pa-3">
            <v-badge
              :color="has_interests.is_active ? 'blue' : 'blue-grey lighten-3'"
              :content="`${has_interests.interests.length}`"
              :offset-x="-3"
            >
              Interests
            </v-badge>
          </div>
        </v-tab>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <div class="d-flex align-center">
                <CreateUrlsForm
                  :buttonText="'Create url'"
                  :type="'has_leads'"
                  @setUrl="(url) => setUrl(url)"
                />
                <v-switch
                  class="mt-0 ml-4"
                  hide-details
                  v-model="has_leads.is_active"
                  :disabled="!has_leads.urls.length"
                  :label="`Rule  ${
                    has_leads.is_active ? 'is active' : 'is not active'
                  }`"
                ></v-switch>
              </div>
              <UrlsTable
                @removeUrl="(index, type) => removeUrl(index, type)"
                :list="has_leads.urls"
                :type="'has_leads'"
              />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <div class="d-flex align-center">
                <CreateUrlsForm
                  :buttonText="'Create url'"
                  :type="'no_leads'"
                  @setUrl="(url) => setUrl(url)"
                />
                <v-switch
                  class="mt-0 ml-4"
                  hide-details
                  v-model="no_leads.is_active"
                  :disabled="!no_leads.urls.length"
                  :label="`Rule  ${
                    no_leads.is_active ? 'is active' : 'is not active'
                  }`"
                ></v-switch>
              </div>
              <UrlsTable
                @removeUrl="(index, type) => removeUrl(index, type)"
                :list="no_leads.urls"
                :type="'no_leads'"
              />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <div class="d-flex align-center">
                <CreateUrlsForm
                  :buttonText="'Create interest'"
                  :type="'interests'"
                  @setUrl="(url) => setUrl(url)"
                />
                <v-switch
                  class="mt-0 ml-4"
                  hide-details
                  v-model="has_interests.is_active"
                  :disabled="!has_interests.interests.length"
                  :label="`Rule  ${
                    has_interests.is_active ? 'is active' : 'is not active'
                  }`"
                ></v-switch>
              </div>
              <InterestsList
                :interests="has_interests.interests"
                @setUrl="(url, interestIndex) => setUrl(url, interestIndex)"
                @removeUrl="
                  (index, type, interestIndex) =>
                    removeUrl(index, type, interestIndex)
                "
              />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </template>
  </v-form>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _ from "lodash";
import {
  LOAD_NETWORKS,
  LOAD_COUNTRIES,
  LOAD_INTERESTS,
  LOAD_TARGET_OFFERS,
} from "@/store/modules/offers/types";
import { requestStatus } from "@/services/services";
import { LOAD_LIST } from "../../../store/modules/domains/types";
import CreateUrlsForm from "@/containers/Offers/components/CreateUrlsForm.vue";
import UrlsTable from "@/containers/Offers/components/UrlsTable.vue";
import InterestsList from "@/containers/Offers/components/InterestsList.vue";
// var slugify = require("slugify");
// import slugify from "slugify";
export default {
  name: "CreateStep1",
  components: {
    CreateUrlsForm,
    UrlsTable,
    InterestsList,
  },
  props: {
    defaultData: Object,
  },
  data: () => ({
    is_loading: false,
    isLoadingNetworks: false,
    isLoadingDomains: false,
    isLoadingCountries: false,
    valid: true,
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 256) || "Name must be less than 256 characters",
    ],
    network: null,
    fieldRules: [(v) => !!v || "This field is required"],
    end_url: null,
    endUrlRules: [(v) => !!v || "End url is required"],
    redirect_type: "meta",
    domain: "",
    searchDomain: "",
    searchCountries: "",
    searchTargetOffers: "",
    country: "",
    enabled: true,
    block_proxies: true,
    block_countries: true,
    block_empty_referers: true,
    rules_status: false,
    has_leads: {
      is_active: false,
      urls: [],
    },
    no_leads: {
      is_active: false,
      urls: [],
    },
    has_interests: {
      is_active: false,
      interests: [],
    },
    windowWidth: window.innerWidth,
    target_offer: null,
    name: "",
  }),
  computed: {
    ...mapState({
      status: (state) => state.offers.status,
      domainsStatus: (state) => state.offers.status,
      networks: (state) => state.offers.networks,
      domains: (state) => state.domains.list,
      countries_list: (state) => state.offers.countries,
      target_offers: (state) => state.offers.target_offers,
    }),
    isActiveFilter: () => [
      {
        label: "Meta Refresh",
        value: "meta",
      },
      {
        label: "302",
        value: "302",
      },
    ],
    domainsList() {
      return this.domain ? [...this.domains, this.domain] : this.domains;
    },
    targetOffersList() {
      return this.target_offer
        ? [...this.target_offers, this.target_offer]
        : this.target_offers;
    },
  },
  methods: {
    ...mapActions({
      onLoadNetworks: `offers/${LOAD_NETWORKS}`,
      onLoadDomains: `domains/${LOAD_LIST}`,
      onLoadCountries: `offers/${LOAD_COUNTRIES}`,
      onLoadInterests: `offers/${LOAD_INTERESTS}`,
      onLoadTargetOffers: `offers/${LOAD_TARGET_OFFERS}`,
    }),
    loadDomainsList(search) {
      this.onLoadDomains(
        `is_active=true&is_banned=false&ordering=-id&search=${
          search ? search : ""
        }`
      );
    },
    // stringToSlug(value) {
    //   this.slug = slugify(value, {
    //     remove: /[^\w ]/g,
    //   });
    // },
    onWaitChangeDomain() {
      if (
        this.searchDomain &&
        (this.searchDomain.length >= 2 || this.searchDomain === "")
      ) {
        // this.onLoadDomains(`search=${this.searchDomain}`);
        this.loadDomainsList(this.searchDomain);
      }
    },

    onWaitChangeCountries() {
      if (
        this.searchCountries &&
        (this.searchCountries.length >= 2 || this.searchCountries === "")
      ) {
        this.onLoadCountries(`search=${this.searchCountries}`);
      }
    },
    onWaitChangeTargetOffers() {
      if (
        this.searchTargetOffers &&
        (this.searchTargetOffers.length >= 2 || this.searchTargetOffers === "")
      ) {
        this.onLoadTargetOffers(`search=${this.searchTargetOffers}`);
      }
    },

    onSave() {
      const is_valid = this.$refs.form.validate();
      if (is_valid) {
        let form_data = {
          name: this.name,
          target_offer: this.target_offer.id,
          country: this.country,
          network: this.network,
          end_url: this.end_url,
          redirect_type: this.redirect_type,
          domain: this.domain.id,
          filters: {
            enabled: this.enabled,
            block_proxies: this.block_proxies,
            block_countries: this.block_countries,
            block_empty_referers: this.block_empty_referers,
          },
          rules: {
            enabled: this.rules_status,
            has_leads: this.has_leads,
            no_leads: this.no_leads,
            has_interests: this.has_interests,
          },
        };

        return form_data;
      }
      return false;
    },
    setDefaultData(defaultData) {
      if (defaultData && defaultData.id) {
        (this.target_offer = defaultData.target_offer),
          (this.country = defaultData.country);
        this.network = defaultData.network;
        this.end_url = defaultData.end_url;
        this.redirect_type = defaultData.redirect_type;
        this.domain = defaultData.domain;
        this.name = defaultData.name;
        this.enabled = defaultData.filters.enabled;
        this.block_proxies = defaultData.filters.block_proxies;
        this.block_countries = defaultData.filters.block_countries;
        this.block_empty_referers = defaultData.filters.block_empty_referers;
        this.rules_status = defaultData.rules.enabled;
        this.has_leads = JSON.parse(
          JSON.stringify(defaultData.rules.has_leads)
        );
        this.no_leads = JSON.parse(JSON.stringify(defaultData.rules.no_leads));
        this.has_interests = JSON.parse(
          JSON.stringify(defaultData.rules.has_interests)
        );
      }
    },
    setUrl(url, interestIndex) {
      switch (url.type) {
        case "interests":
          this.has_interests.interests.push({
            interests: url.interests,
            urls: [url.data],
          });
          break;
        case "interest_url":
          this.has_interests.interests[interestIndex].urls.push(url.data);
          break;
        default:
          this[url.type].urls.push(url.data);
      }
    },
    removeUrl(index, type, interestIndex) {
      switch (type) {
        case "interests":
          this.has_interests.interests.splice(index, 1);
          if (!this.has_interests.interests.length)
            this.has_interests.is_active = false;
          break;
        case "interest_url":
          this.has_interests.interests[interestIndex].urls.splice(index, 1);
          break;
        default:
          this[type].urls.splice(index, 1);
          if (!this[type].urls.length) this[type].is_active = false;
      }
    },
  },
  watch: {
    status(newValue) {
      this.isLoadingNetworks = newValue.load_networks === requestStatus.loading;
    },
    domainsStatus(newValue) {
      this.isLoadingDomains = newValue.load_domains === requestStatus.loading;
      this.isLoadingCountries =
        newValue.load_countries === requestStatus.loading;
    },
    defaultData(newValue) {
      this.setDefaultData(newValue);
    },
    searchCountries() {
      this.onWaitChangeCountries();
    },
    searchTargetOffers() {
      this.onWaitChangeTargetOffers();
    },
    searchDomain() {
      this.onWaitChangeDomain();
    },
  },
  created() {
    this.onLoadNetworks();
    this.onLoadTargetOffers();
    this.onWaitChangeDomain = _.debounce(this.onWaitChangeDomain, 500);
    this.onWaitChangeCountries = _.debounce(this.onWaitChangeCountries, 500);
    this.onWaitChangeTargetOffers = _.debounce(this.onWaitChangeTargetOffers, 500);
    this.loadDomainsList();
    this.onLoadCountries();
    this.onLoadInterests();
    this.setDefaultData(this.defaultData);
  },
};
</script>

<style scoped></style>
