<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card-text>
      <div class="v-label theme--light mt-3 mb-3">
        Template
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              color="info"
              title="Show preview"
              @click="show_preview = !show_preview"
            >
              <v-icon small>mdi-eye </v-icon>
            </v-btn>
          </template>
          <span>{{
            show_preview ? "Hide live preview" : "Show live preview"
          }}</span>
        </v-tooltip>
        <v-tooltip bottom v-if="defaultData && defaultData.offer_url">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              icon
              x-small
              target="_blank"
              title="Preview"
              :href="`${defaultData.offer_url}?holydebug=1`"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small>mdi-open-in-new </v-icon>
            </v-btn>
          </template>
          <span>Preview</span>
        </v-tooltip>
      </div>
      <div class="mb-3 mt-3">
        <iframe
          v-show="show_preview"
          ref="iframe"
          width="100%"
          height="600px"
        ></iframe>
      </div>
      <code-editor
        :languages="[['html', 'HTML']]"
        v-model="template"
        class="codeEditor mb-3"
        height="90vh"
        width="100%"
        font-size="10px"
      />

      <v-btn
        color="primary"
        class="ma-2"
        @click="triggerImport"
        title="Import CSV file"
      >
        Attached files
        <v-icon right dark> mdi-cloud-upload </v-icon>
        <input
          ref="fileInput"
          multiple
          @change="onUpload"
          id="attach"
          style="display: none"
          name="thumbnail"
          type="file"
        />
      </v-btn>
      <v-data-table
        :headers="headers"
        :items="files_list"
        hide-default-footer
        :itemsPerPage="+files_list.length"
        disable-sort
      >
        <template v-slot:header.name="{ header }">
          {{ header.text.toUpperCase() }}
        </template>
        <template v-slot:item.name="{ item }">
          <span>
            <v-btn v-if="isLoadingFiles(item.name)" icon loading> </v-btn>
            <v-icon v-if="isSuccessLoadFiles(item.name)" color="green" small
              >mdi-check</v-icon
            >
            <v-icon v-if="isErrorLoadFiles(item.name)" color="red" small
              >mdi-close</v-icon
            >
            {{ item.name }}
            <v-btn
              v-if="item.file && !isLoadingFiles(item.name)"
              icon
              small
              target="_blank"
              :href="`${item.file}?timestamp=${Date.now()}`"
            >
              <v-icon small>mdi-open-in-new</v-icon>
            </v-btn>
          </span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            v-if="item.id"
            @click="deleteFile({ file_name: item.name, id: item.id })"
            icon
            color="red"
            title="Delete file"
          >
            <v-icon small>mdi-delete </v-icon>
          </v-btn>
          <span v-else></span>
        </template>
      </v-data-table>
    </v-card-text>
  </v-form>
</template>

<script>
import CodeEditor from "simple-code-editor";
import { mapActions, mapState } from "vuex";
import { UPLOAD, DELETE_FILE } from "@/store/modules/offers/types";
import { requestStatus } from "@/services/services";

export default {
  name: "CreateStep2",
  components: { CodeEditor },
  props: {
    defaultData: Object,
    defaultFiles: Array,
    offerId: String,
  },
  data: () => ({
    headers: [
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Mimetype",
        value: "mimetype",
      },
      {
        text: "Size",
        value: "size",
      },
      {
        text: "",
        value: "actions",
      },
    ],
    is_loading: false,
    valid: true,
    template: "",
    files: null,
    files_list: [],
    iframe: null,
    show_preview: false,
  }),
  computed: {
    ...mapState({
      status: (state) => state.offers.status,
      files_status: (state) => state.offers.files_status,
      uploadedFiles: (state) => state.offers.uploadedFiles,
    }),
    offer_domain() {
      return this.defaultData &&
        this.defaultData.domain &&
        this.defaultData.domain.domain
        ? this.defaultData.domain.domain
        : null;
    },
  },
  mounted() {
    this.iframe = this.$refs.iframe;
  },
  methods: {
    ...mapActions({
      upload: `offers/${UPLOAD}`,
      deleteFile: `offers/${DELETE_FILE}`,
    }),
    updateIframePreview(template) {
      this.iframe.contentWindow.document.open();
      this.iframe.contentWindow.document.write(template);
      this.iframe.contentWindow.document.close();
    },
    triggerImport: function () {
      this.$refs.fileInput.click();
    },
    onUpload: function (event) {
      let input = event.target;
      this.files = [...input.files];
    },

    onSave() {
      if (this.template.length) {
        return {
          template: this.template,
        };
      }
      return false;
    },

    isLoadingFiles(fileName) {
      return this.files_status[fileName] === requestStatus.loading;
    },

    isSuccessLoadFiles(fileName) {
      return this.files_status[fileName] === requestStatus.success;
    },

    isErrorLoadFiles(fileName) {
      return this.files_status[fileName] === requestStatus.error;
    },

    setDefaultFiles(defaultData) {
      if (defaultData && defaultData.length) {
        this.files_list = [...defaultData];
      }
    },

    setDefaultData(defaultData) {
      if (defaultData) {
        this.template = defaultData.template;
      }
    },
  },
  watch: {
    template(template) {
      this.updateIframePreview("");
      setTimeout(() => {
        let updatedTemplate = `
<!DOCTYPE html>
<html lang='en'>
  <head>
    <meta charset='UTF-8' />
    <meta name='viewport' content='width=device-width, initial-scale=1.0' />
    <title>Template</title>
  </head>
  <body>
    No template in offer
  </body>
</html>
`;
        if (template && this.defaultData.short_key) {
          const regex = /{{media_root}}/g;
          updatedTemplate = template.replace(
            regex,
            `https://customs.holyaff.com/media/offers/${this.defaultData.short_key}`
          );
        }
        this.updateIframePreview(updatedTemplate);
      }, 100);
    },
    files(newValue) {
      if (newValue && newValue.length) {
        this.offerId &&
          newValue.map((item) => {
            let file = new File([item], encodeURIComponent(item.name), {
              type: item.type,
            });
            let index = this.files_list.findIndex(
              (item) => item.name === file.name
            );

            if (index < 0) {
              this.files_list.push({
                name: item.name,
              });
            }

            this.upload({
              data: file,
              file_name: item.name,
              id: this.offerId,
            });
          });
        this.$refs.fileInput.value = "";
      }
    },
    uploadedFiles(newValue) {
      this.files_list = this.files_list.map((item) => {
        let files = newValue.filter((file) => file.name === item.name);
        return files.length ? files[0] : item;
      });
    },
    status(newValue) {
      if (
        newValue.change_field === "delete_file" &&
        newValue.delete_file === requestStatus.success
      ) {
        const index = this.files_list.findIndex(
          (item) => item.name === newValue.file_name
        );
        if (index > -1) {
          this.files_list.splice(index, 1);
          this.files_list = [...this.files_list];
        }

        this.$toaster.success("Success deleted file");
      }
    },
    defaultFiles(newValue) {
      this.setDefaultFiles(newValue);
    },
    defaultData(newValue) {
      this.setDefaultData(newValue);
    },
  },
  created() {
    this.setDefaultData(this.defaultData);
    this.setDefaultFiles(this.defaultFiles);
  },
};
</script>

<style scoped></style>
