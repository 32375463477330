<template>
  <div>
    <v-simple-table v-if="list && list.length">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              Url
            </th>
            <th class="text-left">
              Enable prefill
            </th>
            <th class="text-left">
              Network
            </th>
            <th>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(url, index) in list"
            :key="index"
              >
                <td>{{url.url}}</td>
                <td>
                  <v-icon small :color="url.enable_prefill ? 'success' : 'error'">
                    {{ url.enable_prefill ? 'mdi-check-circle-outline' :  'mdi-close'}}
                  </v-icon> 
                </td>
                <td> 
                  {{ networks && networks.length && url.network ? networks.find((item) => item.id == url.network).name : '' }}
                  </td>
                <td>
                  <v-btn
                    icon
                    color="red"
                    x-small
                    @click="removeUrl(index)"
                  >
                    <v-icon small>mdi-delete</v-icon>
                  </v-btn>
                </td>
            </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import {mapState} from "vuex";
export default {
  props: ['list', 'type'],
  computed: {
    ...mapState({
      networks: state => state.offers.networks,
    }),
  },
  methods: {
    removeUrl (index) {
      this.$emit('removeUrl', index, this.type);
    }
  }
}
</script>