<template>
  <v-container :fluid="true">
    <v-card>
      <v-card-title class="justify-space-between">
        <span>{{ currentTitle }}</span>
        <v-avatar
            color="primary lighten-2"
            class="subheading white--text"
            size="24"
            v-text="step"
        ></v-avatar>
      </v-card-title>

      <v-window v-model="step">
        <v-window-item :value="1">
          <create-step1 ref="createOffer"/>
        </v-window-item>

        <v-window-item :value="2">
          <v-card-text>
            <create-step2 ref="editOffer" :offer-id="`${created_offer.id}`" />
          </v-card-text>
        </v-window-item>
      </v-window>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            depressed
            @click="onChangeStep(true)"
            :loading="isLoading"
        >
          {{ step === 1 ? 'Next' : 'Save' }}
        </v-btn>
        <v-btn
            v-if="step === 2"
            color="primary"
            outlined
            depressed
            @click="onChangeStep(false)"
            :loading="isLoading"
        >
          Save & Publish
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import {mapActions, mapState} from "vuex";
import CreateStep1 from "./components/CreateStep1";
import CreateStep2 from "./components/CreateStep2";
import {requestStatus} from "@/services/services";
import {CREATE, EDIT} from "@/store/modules/offers/types";

export default {
  name: "OfferCreate",
  components: {CreateStep1, CreateStep2},
  data: () => ({
    step: 1,
    isLoading: false
  }),
  computed: {
    ...mapState({
      status: state => state.offers.status,
      created_offer: state => state.offers.created_offer,
    }),
    currentTitle() {
      switch (this.step) {
        case 1:
          return 'Offer create: Basic offer information'
        case 2:
          return 'Offer create: Template and access'
        default:
          return 'Offer created'
      }
    },
  },
  methods: {
    ...mapActions({
      create: `offers/${CREATE}`,
      edit: `offers/${EDIT}`,
    }),
    onChangeStep(is_draft) {
      switch (this.step) {
        case 1: {
          const data = this.$refs.createOffer.onSave();
          data && this.create({...data, is_draft});
          break;
        }
        case 2: {
          const data = this.$refs.editOffer.onSave();
          if(!data){
            this.$toaster.warning("Template is required");
            return;
          }
          this.created_offer && this.edit({data: {...data, ...this.created_offer, is_draft}, id: this.created_offer.id});
          break;
        }
      }
    }
  },
  watch: {
    status(newValue) {
      this.isLoading = newValue.create === requestStatus.loading || newValue.edit === requestStatus.loading;
      if (
          newValue.change_field === "create" &&
          newValue.create === requestStatus.success &&
          this.created_offer.id
      ) {
        this.step ++;
      }
      if (
          newValue.change_field === "edit" &&
          newValue.edit === requestStatus.success
      ) {
        this.$toaster.success("Success create offer");
        this.$router.push({name: "offers"});
      }
    }
  }
}
</script>

<style scoped>
</style>