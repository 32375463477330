<template>
  <div>
      <v-list
      v-if="interests && interests.length"
      two-line
      subheader
      >
          <v-list-item v-for="(interest, interestIndex) in interests" :key="interestIndex">
            <v-list-item-content>
              <v-list-item-title>
                {{interest.interests.join(',')}}
                <CreateUrlsForm 
                  :buttonText="'Create url'" 
                  :type="'interest_url'" 
                  @setUrl="(url) => setUrl(url, interestIndex)"
                  :typeBtn="'icon'"
                />
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      class="ml-1"
                      color="red"
                      fab
                      icon
                      x-small
                      dark
                      @click="removeUrl(interestIndex, 'interests')"
                    >
                        <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Remove interest</span>
                </v-tooltip>
                <v-divider></v-divider>
              </v-list-item-title>
              <v-list-item-subtitle>
                <UrlsTable @removeUrl="(index,type) => removeUrl(index,type,interestIndex)" :list="interest.urls" :type="'interest_url'"/>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
    </div>
</template>

<script>
import UrlsTable from "@/containers/Offers/components/UrlsTable.vue";
import CreateUrlsForm from "@/containers/Offers/components/CreateUrlsForm.vue";

export default {
    props: ['interests'],
    components: {
      UrlsTable,
      CreateUrlsForm
    },
    methods: {
      setUrl(url, interestIndex) {
        this.$emit('setUrl', url,interestIndex);
      },
      removeUrl(index ,type,interestIndex) {
        this.$emit('removeUrl', index, type, interestIndex);
      }
    }
}
</script>