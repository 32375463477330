<template>
  <v-dialog v-model="dialog" persistent max-width="350">
    <template v-slot:activator="{ on, attrs }">
      <v-tooltip top v-if="typeBtn" v-on="on">
        <template v-slot:activator="{ on }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="success"
            fab
            icon
            x-small
            dark
            @click="dialog = true"
          >
            <v-icon small>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>{{ buttonText }}</span>
      </v-tooltip>

      <v-btn
        v-else
        x-small
        outlined
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
      >
        {{ buttonText }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-h5">
        {{ buttonText }}
      </v-card-title>
      <v-card-text>
        <div>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row class="mb-4">
              <v-col v-if="type === 'interests'" cols="12" md="12">
                <v-autocomplete
                  v-model="interests"
                  :items="interestsList"
                  :loading="isLoadingInterests"
                  :search-input.sync="searchInterests"
                  :rules="[rules.interestsRule]"
                  placeholder="Search"
                  item-text="name"
                  item-value="name"
                  label="Interests *"
                  required
                  dense
                  chips
                  small-chips
                  multiple
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  label="URL"
                  color="info"
                  v-model="url"
                  :rules="[rules.urlRule]"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-checkbox
                  label="Enable prefill"
                  color="info"
                  v-model="enable_prefill"
                  dense
                ></v-checkbox>
              </v-col>
              <v-col v-if="enable_prefill" cols="12" md="12">
                <v-autocomplete
                  v-model="network"
                  :items="networks"
                  item-text="name"
                  item-value="id"
                  label="Network"
                  :rules="[rules.networkRule]"
                  dense
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" outlined @click="dialog = false"> Close </v-btn>
        <v-btn color="primary" @click="addUrl"> Add </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { debounce } from "lodash";
import { LOAD_INTERESTS } from "@/store/modules/offers/types";

export default {
  props: ["type", "buttonText", "typeBtn"],
  data() {
    return {
      isLoadingInterests: false,
      searchInterests: "",
      interests: [],
      dialog: false,
      valid: false,
      url: "",
      enable_prefill: false,
      network: null,
      rules: {
        required: (value) => !!value || "Field is required",
        networkRule: (value) => {
          return this.enable_prefill ? !!value || "Field is required" : true;
        },
        interestsRule: (value) => {
          return this.type === "interests"
            ? !!value.length || "Field is required"
            : true;
        },
        urlRule: (value) => {
          const pattern =
            // eslint-disable-next-line
            /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
          return pattern.test(value) || "Incorect url";
        },
      },
    };
  },
  watch: {
    dialog(dialog) {
      if (!dialog) {
        this.url = "";
        this.enable_prefill = false;
        this.network = null;
        this.interests = [];
        this.$refs.form.reset()
      }
    },
    enable_prefill(enable_prefill) {
      if (!enable_prefill) this.network = null;
    },
    searchInterests() {
      this.onWaitChangeInterests();
    },
  },
  computed: {
    ...mapState({
      networks: (state) => state.offers.networks,
      interestsList: (state) => state.offers.interests,
    }),
  },
  methods: {
    ...mapActions({
      onLoadInterests: `offers/${LOAD_INTERESTS}`,
    }),
    addUrl() {
      const is_valid = this.$refs.form.validate();
      if (is_valid) {
        let url = {
          type: this.type,
          data: {
            url: this.url,
            enable_prefill: this.enable_prefill,
            network: this.network,
          },
        };
        if (this.type === "interests") url["interests"] = this.interests;
        this.$emit("setUrl", url);
        this.dialog = false;
      }
    },
    onWaitChangeInterests: debounce(function () {
      this.onLoadInterests(
        `search=${
          this.searchInterests && this.searchInterests.length >= 2
            ? this.searchInterests
            : ""
        }`
      );
    }, 500),
  },
};
</script>
